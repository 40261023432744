import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Modal, SvgIcon, TextByLines } from '@/modules/shared/components';
import s from './company-review-modal.module.scss';

type CompanyReviewModalProps = {
  status: string;
};

const MASCOTS = {
  '200_company_verified': '/assets/images/rabbit-fun.svg',
  '200_company_rejected': '/assets/mascots/rabbit-crying.svg',
  '200_company_resend': '/assets/images/rabbit-success.svg',
  '400_token_is_invalid_or_expired': '/assets/mascots/rabbit-not-found.svg',
  '400_already_approved': '/assets/mascots/rabbit-not-found.svg',
  '400_already_rejected': '/assets/mascots/rabbit-not-found.svg',
  '400_company_does_not_exist': '/assets/mascots/rabbit-not-found.svg',
  '400_user_does_not_exist': '/assets/mascots/rabbit-not-found.svg',
};

export const CompanyReviewModal = ({ status }: CompanyReviewModalProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Modal
      open
      title={<SvgIcon name="logo-main-medium" style={{ width: '102px', height: '32px' }} />}
      showBackButton={false}
      classNameHeader={s.header}
      onClose={() => router.replace(router.pathname, undefined, { shallow: true })}
    >
      <div className={s.content}>
        <h2>
          <TextByLines text={t(`index-page:moderatorModal.${status}`)} />
        </h2>
        <Image
          width={165}
          height={165}
          src={MASCOTS[status] ?? '/assets/mascots/rabbit-not-found.svg'}
          alt={status}
        />
      </div>
    </Modal>
  );
};
